import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layouts"
import styled from "styled-components"
import SEO from "../components/SEO"

const BottomSpacer = styled.div`
  margin-bottom: 400px;
  @media (max-width: 500px) {
    margin-bottom: 500px;
  }
  @media (max-width: 400px) {
    margin-bottom: 600px;
  }
`
const PageTitle = styled.h2`
  text-align: center;
  margin: 20px;
`
const PageSubTitle = styled.h3`
  
  font-style: italic;
  
`
const SeminarClass = styled.div`
border: 1px solid black;
  width: 95%;
  margin: auto;
  padding: 10px;
  margin-top: 20px;
`
const Title = styled.h4``
const Presenter = styled.h5`
  padding: 10px;
  font-style: italic;
`
const Description = styled.div`
  font-size: 16px;
  margin: 20px 0;
`
const BotDescription = styled.div`
  text-align: center;
  font-size: small;
  margin-top: 20px;
`
const Seminar = () => {
  const { allSanitySeminar } = useStaticQuery(graphql`
    query {
      allSanitySeminar {
        nodes {
          author
          description
          title
          startDate(formatString: "dddd MMMM Do, YYYY")
          duration
        }
      }
    }
  `)
 
  return (
    <Layout>
      <SEO title="Seminar Schedule" />

      <PageTitle>Summer Seminar 2021 Classes </PageTitle>
      <PageSubTitle>School of the Natural Order  
Baker, 
</PageSubTitle>
<PageSubTitle> Nevada  
</PageSubTitle>
      <div>
        {allSanitySeminar.nodes.map(seminar => (
          <SeminarClass key={`key-${seminar.title}`}>
            <Title>
              <strong>{seminar.title}</strong>
            </Title>

            {seminar.author ? (
              <Presenter>Presented by {seminar.author}.</Presenter>
            ) : null}
            <Description>{seminar.description}</Description>
          </SeminarClass>
        ))}
      </div>

    <BotDescription>All classes are offered with hearts wide open. For those who so wish, a donation can be made via the school's website utilizing PayPal, or by sending a check. (If utilizing PayPal, please designate your donation by using the Online Classes tab. If donating by check, please designate for Online Classes.) 
You are very much appreciated! Thank you for your continued support and goodwill.
</BotDescription>
<BotDescription> Namaste’
</BotDescription>

      <BottomSpacer />
    </Layout>
  )
}
export default Seminar
